<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">{{ title }}</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <el-form class="elform" :model="ruleForm" ref="ruleForm" label-width="100px">
        <el-form-item label="时间:">
          <el-date-picker
            v-model="timevalue"
            type="datetimerange"
            range-separator="--"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item class="elinput" label="原因:">
          <el-input
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 8 }"
            placeholder="请输入内容"
            v-model="ruleForm.reason"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item class="elinput" label="附件">
          <UploadFiles
            :files="fileList"
            :module="'practice_leave,file'"
            :cloudType="'practiceLeave/file'"
            @setUrl="getUrl"
          />
        </el-form-item>
      </el-form>

      <div class="bts">
        <div class="bt" @click="toback">取消</div>
        <div class="bt bt1" @click="submit">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs"
import { addpracticeleave, getpracticeleaveinfo } from "@/api/practiceleave"
import UploadFiles from '@/components/upload-files'
export default {
  name: "mainpage",
  components: {
    UploadFiles
  },
  data () {
    return {
      title: "新增请假",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/数据 (1).png"),
      icon2: require("@/assets/coursedetails/43443.png"),
      icon3: require("@/assets/coursedetails/43443.png"),
      timevalue: [],
      ruleForm: {
        id: '',
        practice_plan_id: "",
        start_time: "",
        end_time: "",
        reason: "",
        file: "",
      },
      fileList: [],
    }
  },
  computed: {
    plan_id () {
      return this.$route.query.plan_id
    },
    edit_id () {
      return this.$route.query.id
    },
    type () {
      return this.$route.query.type
    }
  },
  created () {
    if (this.type == "edit") {
      this.title = "编辑请假"
      this.getpracticeleaveinfo({ id: this.edit_id })
    }
  },
  methods: {
    toback () {
      this.$router.push({
        path: "/home/personalcenter/myleave",
        query: {
          plan_id: this.plan_id,
        },
      })
    },
    todetail () {
      this.$router.push({
        path: "/home/personalcenter/interactiveqadetails",
      })
    },
    submit () {
      //判断请假时间是否填写合理
      if (this.timevalue.length && this.timevalue.length == 2) {
        this.ruleForm.start_time = dayjs(this.timevalue[0]).unix()
        this.ruleForm.end_time = dayjs(this.timevalue[1]).unix()
      } else {
        this.$message.error("请正确选择请假时间")
        return false
      }
      //判断请假理由是否填写完整
      if (!this.ruleForm.reason) {
        this.$message.error("请输入请假原因")
        return false
      }
      this.ruleForm.practice_plan_id = this.plan_id
      if (this.type == "edit") {
        this.ruleForm.id = this.edit_id
        this.addpracticeleave(this.ruleForm)
      } else {
        this.addpracticeleave(this.ruleForm)
      }
    },
    addpracticeleave (params) {
      addpracticeleave(params)
        .then((response) => {
          if (response.code === 0) {
            this.toback()
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },

    getpracticeleaveinfo (params) {
      getpracticeleaveinfo(params)
        .then((response) => {
          if (response.code === 0) {
            let start_time = dayjs
              .unix(response.data.start_time)
              .format("YYYY-MM-DD HH:mm:ss")
            let end_time = dayjs
              .unix(response.data.end_time)
              .format("YYYY-MM-DD HH:mm:ss")
            this.timevalue = [start_time, end_time]
            this.ruleForm.reason = response.data.reason
            this.ruleForm.file = JSON.stringify(response.data.file)
            this.fileList = response.data.file
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    checkbt () {
      this.$refs.customdialog.dialogopenbt()
    },
    // //产生一个随机id
    // generateUUID() {
    //   var d = new Date().getTime();
    //   if (window.performance && typeof window.performance.now === "function") {
    //     d += performance.now(); //use high-precision timer if available
    //   }
    //   var uuid = "xxxxxxxxxxxxxxxyxxxxxxxxxxxxxxx".replace(
    //     /[xy]/g,
    //     function (c) {
    //       var r = (d + Math.random() * 16) % 16 | 0;
    //       d = Math.floor(d / 16);
    //       return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    //     }
    //   );
    //   return uuid;
    // },
    // 获取文件上传的返回值
    getUrl (data, key) {
      this.ruleForm.file = data
    }
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  min-height: calc(100vh - 210px);
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0773fc;
      user-select: none;
      white-space: nowrap;
    }
    .retutnbt {
      margin-right: 40px;
      width: 88px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background: #0773fc;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
    }
  }
  .line {
    margin-top: 10px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    background-color: #ffffff;
    border-radius: 4px;
    padding-top: 60px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .elform {
      .elinput {
        width: 600px;
      }
      .file-select-contain {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .file-selecttip {
          margin-left: 10px;
          padding-left: 10px;
          text-align: left;
          width: 200px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #d8d8d8;
          border-radius: 4px;
        }
        .file-selectbt {
          margin-left: 10px;
          font-size: 15px;
          font-weight: 400;
          color: #ffffff;
          width: 121px;
          height: 40px;
          line-height: 40px;
          padding: 0px;
          text-align: center;
          background: #0773fc;
          border: 1px solid #0773fc;
          border-radius: 4px;
          &:active {
            background: #0773fc;
          }
        }
      }
    }
    .bts {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .bt {
        margin-right: 10px;
        width: 88px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 4px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #a0a0a0;
        user-select: none;
        cursor: pointer;
      }
      .bt1 {
        background: #0773fc;
        color: #ffffff;
      }
    }
  }
}
</style>
